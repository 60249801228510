import { Paragraph } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';

export const quoteModidiers = {
  justText: (theme) => css`
    align-self: center;

    @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
      font-size: 25px;
      line-height: 32px;
    }

    .icon {
      fill: ${theme.colors.primary.default};
    }
  `,
  withImage: (theme) => css`
    background: ${({ theme }) =>
      `linear-gradient(to bottom, ${theme.colors.grey.dark}00 0%, ${theme.colors.grey.dark}B3 64px)`};
    align-self: end;
    color: ${theme.colors.white};
  `,
};

export const QuoteWrapper = styled.blockquote`
  --quote-padding: 40px;
  display: grid;
  gap: 16px;
  padding: var(--quote-padding);
  position: relative;
  text-align: left;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --quote-padding: 24px;
  }

  &.image {
    ${({ theme }) => quoteModidiers.withImage(theme)};
  }

  &.text {
    ${({ theme }) => quoteModidiers.justText(theme)};
  }

  * {
    max-width: calc(var(--testimonial-width) - (var(--quote-padding) * 2));
  }
`;

export const Text = styled(Paragraph)`
  display: block;
  user-select: none;
`;
