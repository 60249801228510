import { trackEvent } from '@/lib/segment';

export const ACTIONS = {
  REDIRECT_TO_ONBOARDING: 'redirect_to_onboarding',
  REDIRECT_TO_PRODUCT_LP: 'redirect_to_product_lp',
  REDIRECT_TO_SIGN_IN: 'redirect_to_sign_in',
  REDIRECT_TO_TRIAGE: 'redirect_to_triage',
  REDIRECT_TO_APP: 'redirect_to_app',
  REDIRECT_TO_FORM: 'redirect_to_form',
  REDIRECT_TO_LP: 'redirect_to_lp',
  OPEN_MODAL: 'open_modal',
  OPEN_DRAWER: 'open_drawer',
  NAVIGATION: 'navigation',
  REDIRECT_TO_COVERAGE: 'redirect_to_coverage',
  WAITING_LIST_FORM_SUBMITTED: 'waiting_list_form_submitted',
  NOT_COVERED_FORM_SUBMITTED: 'not_covered_form_submitted',
  CHAT_OPENED: 'Chat Opened',
  MESSAGE_SENT: 'Chat Message Sent',
  SCROLL_DOWN: 'scroll_down',
  TRACK: 'track',
};

export const BUTTON_TYPES = {
  NAVIGATION: 'navigation',
  SUBMIT: 'submit',
};

export const EVENT_NAMES = {
  BUTTON_CLICKED: 'Button Clicked',
  CLIENT_ELIGIBILITY_CHECKED: 'Client Eligibility checked',
  FORM_SUBMIT: 'Form submitted',
  AUDIO_PLAY_START: 'Audio Playback Started',
  AUDIO_PAUSE: 'Audio Playback Paused',
  AUDIO_PLAY_RESUME: 'Audio Playback Resumed',
  AUDIO_COMPLETED: 'Audio Playback Completed',
  AUDIO_EXITED: 'Audio Playback Exited',
  VIDEO_PLAY_START: 'Video Playback Started',
  VIDEO_PAUSE: 'Video Playback Paused',
  VIDEO_PLAY_RESUME: 'Video Playback Resumed',
  VIDEO_COMPLETED: 'Video Playback Completed',
  VIDEO_EXITED: 'Video Playback Exited',
};

const filterEmptyValues = (data) => {
  return Object.fromEntries(Object.entries(data).filter(([, value]) => value !== ''));
};

export function trackButtonClicked({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  buttonSolution = '',
  destination = '',
  url,
  pageTitle = '',
}) {
  let title = pageTitle;

  if (url && !pageTitle) {
    const urlSplit = url.split('/');
    title = urlSplit[urlSplit.length - 1].replace('-', ' ');
  }

  const eventData = {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    button_solution: buttonSolution,
    page_url: url,
    destination,
    page_title: title,
  };

  trackEvent(EVENT_NAMES.BUTTON_CLICKED, filterEmptyValues(eventData));
}

export function trackClientEligibilityChecked({
  clientName,
  clientId,
  clientCountry,
  clientExists,
  ...props
}) {
  trackEvent(EVENT_NAMES.CLIENT_ELIGIBILITY_CHECKED, {
    client_name: clientName,
    client_id: clientId,
    client_country: clientCountry,
    client_exists: clientExists,
    ...props,
  });
}

export function trackConditionSelected({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  destination = '',
  url,
  conditionName,
  productName,
}) {
  trackEvent(EVENT_NAMES.BUTTON_CLICKED, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    page_url: url,
    destination,
    condition: conditionName,
    program_name: productName,
  });
}

export function trackFormSubmitted({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  client,
  consent = false,
}) {
  trackEvent(EVENT_NAMES.FORM_SUBMIT, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    client_name: client,
    consent_to_reach_client: consent,
  });
}

export function trackAudioPlayed(
  event,
  { sessionId, length, src, percentageListened, ...properties },
) {
  trackEvent(event, {
    ...properties,
    audio_player: 'local',
    action: ACTIONS.TRACK,
    content_asset_id: src,
    total_length: length,
    percentage_listened: percentageListened,
    session_id: sessionId,
  });
}

export function trackVideoPlayed(
  event,
  { sessionId, length, src, percentageWatched, ...properties },
) {
  trackEvent(event, {
    ...properties,
    video_player: 'local',
    action: ACTIONS.TRACK,
    content_asset_id: src,
    total_length: length,
    percentage_watched: percentageWatched,
    session_id: sessionId,
  });
}
