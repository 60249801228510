import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { components } from 'react-select';
import InputLabel from '@/components/core/form/input/input-label';
import Image from 'next/image';

import { baseStyles } from './dropdown.styles';
import ErrorLabel from '@/components/core/form/input/error-label';
import { CustomValueContainer } from '../CustomValueContainer';
import { useState } from 'react';

const DropdownIndicator = (props) => {
  const isLoading = props?.selectProps?.isLoading;
  return (
    <>
      {!isLoading ? (
        <components.DropdownIndicator {...props}>
          {!props?.selectProps?.inputValue && !props?.hasValue ? (
            <Image src="/icons/icon_search.svg" width={22} height={22} alt="search" priority />
          ) : (
            <ButtonReset
              className="buttonReset"
              aria-label="Delete selected"
              onClick={props?.selectProps?.handleReset}
            >
              <Image src="/icons/ic_close.svg" width={22} height={22} alt="delete selected" />
            </ButtonReset>
          )}
        </components.DropdownIndicator>
      ) : null}
    </>
  );
};
const CompaniesDropdown = ({
  className,
  label,
  ariaLabel,
  name,
  options,
  value,
  onChange,
  onBlur,
  placeholder,
  field,
  error,
  errorLabel,
  disabled,
  isSearchable = true,
  loadOptions,
  noOptionsMessage,
  customBaseStyles,
  innerRef,
  onFocus,
  handleReset,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={className}>
      {label && <InputLabel htmlFor={field ? field.name : name}>{label}</InputLabel>}

      <AsyncSelect
        handleReset={() => handleReset()}
        ref={innerRef}
        instanceId={field ? field.name : name}
        aria-label={ariaLabel}
        defaultOptions={options}
        cacheOptions={true}
        value={value}
        onChange={(option) => onChange(option)}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
        hasValue={value}
        onFocus={() => {
          setFocused(true);
          onFocus();
        }}
        onBlur={() => {
          setFocused(false);
          onBlur;
        }}
        isFocused={focused}
        placeholder={placeholder}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ValueContainer: CustomValueContainer,
        }}
        styles={customBaseStyles ? customBaseStyles({ error }) : baseStyles()}
        error={error}
        isDisabled={disabled}
        isSearchable={isSearchable}
        minMenuHeight={246}
        maxMenuHeight={246}
        menuPlacement="auto"
      />
      {error && errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
    </div>
  );
};

const CompaniesDropdownStyled = styled(CompaniesDropdown)`
  margin-bottom: 16px;

  .buttonReset {
    padding: 0;
  }
`;
const ButtonReset = styled.button`
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export default CompaniesDropdownStyled;
