import { components } from 'react-select';
import Image from 'next/image';

import theme from '@/utils/styles-variables';

export function DropdownIndicator(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <Image src="/icons/arrow_down.svg" width={13} height={7} alt="arrow down" />
    </components.DropdownIndicator>
  );
}

export function baseStyles() {
  return {
    control: (styles, state) => ({
      ...styles,
      height: 64,
      lineHeight: 'normal',
      padding: '0 8px',
      cursor: 'pointer',
      backgroundColor: theme.colors.white,
      opacity: state.isDisabled ? 0.4 : 1,
      boxShadow: 'none',
      ':hover': {
        borderColor: theme.colors.grey,
      },
      borderRadius: 16,
      fontSize: 14,
    }),
    placeholder: (styles, state) => ({
      ...styles,
      margin: 0,
      fontSize:
        state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused ? 12 : 14,
      fontWeight: 500,
      color: theme.colors.grey[600],
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      position: 'absolute',
      top:
        state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused
          ? '9px'
          : '21px',
      left: '16px',
      transition: 'top 0.1s, font-size 0.1s',
      textAlign: 'left',
      width: 'calc(100% - 72px)',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: 16,
      fontWeight: 400,
      color: theme.colors.black,
      letterSpacing: 0,
      lineHeight: '20px',
      marginTop: 16,
      textAlign: 'left',
    }),
    input: (styles, state) => ({
      ...styles,
      fontSize: 16,
      fontWeight: 500,
      color: theme.colors.black,
      marginTop:
        state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused ? 16 : 0,
    }),
    menu: (styles) => ({
      ...styles,
      position: 'absolute',
      width: '100%',
      zIndex: 10,
      marginTop: 13.5,
      boxShadow: '0 2px 10px 0 rgba(218, 220, 230, 0.5)',
      backgroundColor: theme.colors.white,
      border: `1px solid #f6f6f6`,
      borderRadius: 4,
    }),
    menuList: () => ({
      padding: '6px 0',
      maxHeight: 246,
      overflow: 'auto',
      backgroundColor: 'rgba(25,47,65,0.03)',
    }),
    option: (styles, state) => ({
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '38px',
      color: state.isSelected ? theme.colors.black : theme.colors.grey,
      backgroundColor: state.isFocused ? 'rgba(25,47,65,0.03)' : theme.colors.white,
      '&:hover': {
        backgroundColor: 'rgba(25,47,65,0.03)',
        color: theme.colors.black,
      },
      padding: '0 20px',
      outline: 'none',
      transition: 'background-color 500ms, color 500ms',
      cursor: 'pointer',
      textAlign: 'left',
    }),
    indicatorsContainer: () => ({
      paddingRight: 5.35,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      margin: '2px 8px',
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '38px',
      color: theme.colors.gray,
    }),
  };
}
