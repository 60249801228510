import * as Sentry from '@sentry/nextjs';
import { notFound } from 'next/navigation';

export async function getPlatformClient({ client, preview = false }) {
  const res = preview
    ? await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/platform-clients/findByKey/${client}?preview=true`,
        {
          cache: 'no-store',
        },
      )
    : await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/platform-clients/findByKey/${client}`, {
        next: { revalidate: 60 },
      });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}

export async function getPlatformMainPage() {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_CMS_URL}/api/platform-main-page/getPopulated`,
    {
      next: { revalidate: 60 },
    },
  );

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}

export async function getWaitingListPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/waiting-list/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}
export async function getNotCoveredPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/not-covered/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}

export async function getCheckEligibility() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/eligibility-check/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}
