import { useCallback } from 'react';

// Components
import Modal from '../Modal';

import useVideoTracking from '@/utils/useVideoTracking';

// Styles
import { GridContainer, CloseButton, IconClose, StyledVideo } from './styles';

const VideoPlayerModal = ({ modalIsOpen, closeModal, videoURL }) => {
  const { onVideoExit, ...trackVideo } = useVideoTracking({ src: videoURL });

  const onClose = useCallback(() => {
    onVideoExit();
    closeModal();
  }, [onVideoExit, closeModal]);

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={onClose}>
      <GridContainer>
        {modalIsOpen && (
          <CloseButton type="button" onClick={onClose}>
            <IconClose alt="" src="/icons/circle-close.svg" width="20" height="20" />
          </CloseButton>
        )}
        <StyledVideo controls autoPlay playsInline width="100%" {...trackVideo}>
          <source src={videoURL} />
        </StyledVideo>
      </GridContainer>
    </Modal>
  );
};

export default VideoPlayerModal;
