import { Heading } from '@swordhealth/ui-corporate';
import { QuoteWrapper, Text } from './style';

import QuoteIcon from '@/public/icons/icon-quote.svg';

const Quote = ({ id, name, condition, quote, withImage }) => (
  <QuoteWrapper className={withImage ? 'image' : 'text'}>
    <QuoteIcon className="icon" />
    <Heading id={`${id}_title`} data-testid="quote" as="p" size="xl" weight="bold">
      {quote}
    </Heading>
    {(name || condition) && (
      <div>
        {name && (
          <Text id={`${id}_name`} data-testid="quote-name" size="md" weight="regular">
            {name}
          </Text>
        )}
        {condition && (
          <Text
            id={`${id}_condition`}
            data-testid="quote-condition"
            $light
            size="md"
            weight="regular"
          >
            {condition}
          </Text>
        )}
      </div>
    )}
  </QuoteWrapper>
);

export default Quote;
